<template>
  <section class="daily-cases-controller">
    <p class="daily-cases-controller__title">{{ $t('freeCases') }}</p>
    <div v-if="dailyCasesStore.userInfo.userId" class="daily-cases-controller__container">
      <MainPageDailyCasesAvailableCases :user-info="dailyCasesStore.userInfo" />

      <MainPageDailyCasesProfile
        :user-info="dailyCasesStore.userInfo"
        @on-generate-level-click="handlerGenerateLevelClick"
      />
    </div>
    <div v-else class="daily-cases-controller__unauth">
      <p>{{ $t('mainPage.dailyCases.unAuthText1') }}</p>
      <div class="daily-cases-controller__auth-btns">
        <GeneralAuthButtonController :amplitude-source-of-click="EAuthButtonPlaceClicked.FREE_CASES_BLOCK" />
      </div>
      <I18nT keypath="mainPage.dailyCases.unAuthText2" tag="p">
        <template #link>
          <NuxtLinkLocale :to="useLinks().FREE_CASES" class="daily-cases-controller__link">
            {{ $t('mainPage.dailyCases.linkText') }}
          </NuxtLinkLocale>
        </template>
      </I18nT>
    </div>
  </section>
</template>

<script setup lang="ts">
import { useDailyCasesStore } from '~/features/mainPage/store/dailyCases.store';
import { useAlertStore } from '~/store/alert/alert.store';
import { EAuthButtonPlaceClicked } from '~/repository/amplitude/types/auth.types';

const { t } = useI18n();

const alertStore = useAlertStore();

const dailyCasesStore = useDailyCasesStore();

const handlerGenerateLevelClick = async () => {
  const result = await dailyCasesStore.generateLevel();
  if (result) return;

  alertStore.showError({
    message: t('mainPage.dailyCases.generationError.message'),
    title: t('mainPage.dailyCases.generationError.title'),
  });
};
</script>

<style scoped lang="scss" src="./DailyCasesController.scss" />
