<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="daily-cases-available-cases">
    <template v-if="userInfo.availableCases?.length">
      <div :class="['daily-cases-available-cases__container', casesContainerClasses]">
        <div
          v-for="(item, id) in userInfo.availableCases"
          :key="id"
          class="daily-cases-available-cases__image-container"
        >
          <NuxtImg
            v-if="getImagePath(item.id)"
            provider="localProvider"
            class="daily-cases-available-cases__image"
            :src="getImagePath(item.id)"
          />
        </div>
      </div>
      <div class="daily-cases-available-cases__subtext">
        {{ userInfo.availableCases?.length }} {{ $t('mainPage.dailyCasesAvailableCases.amount') }}
      </div>
    </template>
    <template v-else>
      <div class="daily-cases-available-cases__block">
        <template v-if="userInfo.userLevel">
          <p v-if="+userInfo.userLevel > 0">
            {{ $t('mainPage.dailyCasesAvailableCases.cooldown') }}
            <span class="daily-cases-available-cases__block_highlighted">
              {{ timerData.hours }}:{{ timerData.minutes }}:{{ timerData.seconds }}
            </span>
          </p>
          <I18nT keypath="mainPage.dailyCasesAvailableCases.moreExp" tag="p">
            <template #exp>
              <span class="daily-cases-available-cases__block_highlighted">{{ xpLeftToNextLvl }}</span>
            </template>
            <template #link>
              <NuxtLinkLocale class="daily-cases-available-cases__block_link" :to="userInfo.nextLvlCase?.link">
                {{ nextLvlCaseText }}
              </NuxtLinkLocale>
            </template>
          </I18nT>
        </template>
        <template v-else>
          <p>{{ $t('mainPage.dailyCasesAvailableCases.noLevel1') }}</p>
          <p>{{ $t('mainPage.dailyCasesAvailableCases.noLevel2') }}</p>
        </template>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import type { IDailyCasesAvailableCasesProps } from './DailyCasesAvailableCases.types';
import DCImage1 from 'assets/images/mainPage/dailyCases/lvl1.png';
import DCImage5 from 'assets/images/mainPage/dailyCases/lvl5.png';
import DCImage10 from 'assets/images/mainPage/dailyCases/lvl10.png';
import DCImage15 from 'assets/images/mainPage/dailyCases/lvl15.png';
import DCImage20 from 'assets/images/mainPage/dailyCases/lvl20.png';
import DCImage30 from 'assets/images/mainPage/dailyCases/lvl30.png';
import DCImage40 from 'assets/images/mainPage/dailyCases/lvl40.png';
import DCImage50 from 'assets/images/mainPage/dailyCases/lvl50.png';
import DCImage60 from 'assets/images/mainPage/dailyCases/lvl60.png';
import DCImage70 from 'assets/images/mainPage/dailyCases/lvl70.png';
import DCImage80 from 'assets/images/mainPage/dailyCases/lvl80.png';
import DCImage90 from 'assets/images/mainPage/dailyCases/lvl90.png';
import DCImage100 from 'assets/images/mainPage/dailyCases/lvl100.png';
import DCImage110 from 'assets/images/mainPage/dailyCases/lvl110.png';
import DCImage120 from 'assets/images/mainPage/dailyCases/lvl120.png';
import DCImage130 from 'assets/images/mainPage/dailyCases/lvl130.png';
import DCImage140 from 'assets/images/mainPage/dailyCases/lvl140.png';
import DCImage160 from 'assets/images/mainPage/dailyCases/lvl160.png';
import DCImage180 from 'assets/images/mainPage/dailyCases/lvl180.png';
import DCImage200 from 'assets/images/mainPage/dailyCases/lvl200.png';

const { t } = useI18n();

const changeXL = GlobalUtils.Media.changeByMedia('xl');

const props = defineProps<IDailyCasesAvailableCasesProps>();
const { userInfo } = toRefs(props);

const refinedTime = ref(0);
const { timerData, start } = useTimer(refinedTime);

onBeforeMount(() => {
  watch(
    userInfo,
    (value) => {
      refinedTime.value = value?.nearestCaseTime ?? 0;
      start();
    },
    { deep: true, immediate: true },
  );
});

const dailyCaseImages = [
  { id: 1, src: DCImage1 },
  { id: 5, src: DCImage5 },
  { id: 10, src: DCImage10 },
  { id: 15, src: DCImage15 },
  { id: 20, src: DCImage20 },
  { id: 30, src: DCImage30 },
  { id: 40, src: DCImage40 },
  { id: 50, src: DCImage50 },
  { id: 60, src: DCImage60 },
  { id: 70, src: DCImage70 },
  { id: 80, src: DCImage80 },
  { id: 90, src: DCImage90 },
  { id: 100, src: DCImage100 },
  { id: 110, src: DCImage110 },
  { id: 120, src: DCImage120 },
  { id: 130, src: DCImage130 },
  { id: 140, src: DCImage140 },
  { id: 160, src: DCImage160 },
  { id: 180, src: DCImage180 },
  { id: 200, src: DCImage200 },
];

const getImagePath = computed(() => (imageId: string) => {
  return dailyCaseImages.find((image) => image.id === +imageId)?.src;
});

const casesContainerClasses = computed(() => ({
  '--couple-items': (userInfo.value?.availableCases ?? []).length < changeXL(4, 5),
}));

const dailyCaseImageWidth = computed(() => {
  return GlobalUtils.CSS.proceedCssValue(changeXL(72, 48), 'px');
});

const xpLeftToNextLvl = computed(() => {
  return userInfo.value?.nextLvlXp ? +userInfo.value?.nextLvlXp : 0;
});

const nextLvlCaseText = computed<string>(() => {
  const caseName = userInfo.value.nextLvlCase?.name ?? '';
  return t('mainPage.dailyCasesAvailableCases.nextLvlCase', { caseName });
});
</script>

<style scoped lang="scss">
.daily-cases-available-cases {
  --daily-case-image-width: v-bind('dailyCaseImageWidth');
}
</style>

<style scoped lang="scss" src="./DailyCasesAvailableCases.scss"></style>
