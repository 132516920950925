<template>
  <main>
    <MainPageBattlepassControllerSkeleton />
    <section class="content">
      <MainPageAllCasesControllerSkeleton>
        <FreeCasesController v-if="freeCasesStore.cases.length && authStore.isAuth" />
      </MainPageAllCasesControllerSkeleton>

      <MainPageAllCasesControllerSkeleton>
        <AllCasesController v-if="allCasesStore.sections.length" />
      </MainPageAllCasesControllerSkeleton>
    </section>
  </main>
</template>

<script setup lang="ts">
import FreeCasesController from '~/features/mainPage/controllers/FreeCasesController/FreeCasesController.vue';
import AllCasesController from '~/features/mainPage/controllers/AllCasesController/AllCasesController.vue';

import { useBattlepassStore } from '~/features/mainPage/store/battlepass.store';
import { useAuthStore } from '~/features/authentication/store/authorization';
import { useFreeCasesStore } from '~/features/mainPage/store/freeCases.store';
import { useAllCasesStore } from '~/features/mainPage/store/allCases.store';

definePageMeta({
  layout: 'main-page',
});

const authStore = useAuthStore();
const allCasesStore = useAllCasesStore();
const battlepassStore = useBattlepassStore();
const freeCasesStore = useFreeCasesStore();

const { accessToken } = storeToRefs(authStore);

const { refresh } = useAsyncData(
  'main-page-data',
  () => {
    return Promise.allSettled([battlepassStore.fetch(), freeCasesStore.fetch(), allCasesStore.fetch()]);
  },
  {
    lazy: true,
    server: false,
  },
);

watch(accessToken, () => refresh());
</script>

<style scoped lang="scss" src="./MainPage.scss" />
